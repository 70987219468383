.ant-popover-inner-content{
  padding: 0px !important;
}
.ant-popover-arrow{
  display: none;
}
.ant-popover-inner{
  box-shadow: none !important;
  background-color: transparent !important;
}
